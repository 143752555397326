<template>
  <v-card class="coctel-card">
    <v-row
      v-if="isPair"
      class="coctel-row"
      align="center"
    >
      <v-col
        cols="7"
        offset="1"
      >
        <h2>{{ value.name }}</h2>
        <h4 class="grey--text">{{ content.subtitle }}</h4>
        <p
          class="text-justify"
          v-html="content.desc"
        ></p>
      </v-col>
      <v-col
        cols="4"
      >
        <v-img
          :src="require('@/assets/mixologia/' + value.image)"
          height="200"
          width="100%"
          contain
        />
      </v-col>
    </v-row>
    <v-row
      v-if="!isPair"
      class="coctel-row"
      align="center"
    >
      <v-col
        cols="4"
      >
        <v-img
          :src="require('@/assets/mixologia/' + value.image)"
          height="200"
          width="100%"
          contain
        />
      </v-col>
      <v-col
        cols="7"
      >
        <h2>{{ value.name }}</h2>
        <h4 class="grey--text">{{ content.subtitle }}</h4>
        <p
          class="text-justify"
          v-html="content.desc"
        ></p>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  export default {
    name: 'MixologiaCoctel',
    props: {
      value: {
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      isPair () {
        return this.value.id % 2 === 0
      },
      content () {
        return this.value[this.$store.state.lang]
      },
    },
  }
</script>

<style scoped>
  .coctel-card {
    margin-bottom: 25px;
  }
  .coctel-row {
    padding: 25px;
  }
</style>
